import get from 'lodash/get'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import { reactive } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    id: null,
    lang: null,
    url: '',
    seo_name: '',
    meta_title: '',
    meta_h1: null,
    meta_description: null,
    meta_keyword: null,
    meta_thumbnail: null,
    geo_title: null,
    geo_detail: null,
    created_at: null,
    folder_name: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const formData = reactive({
    id: null,
    lang: null,
    url: '',
    seo_name: '',
    meta_title: '',
    meta_h1: null,
    meta_description: null,
    meta_keyword: null,
    meta_thumbnail: null,
    geo_title: null,
    geo_detail: null,
    created_at: null,
    folder_name: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const prepareFormData = (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      if (key === 'url' && data[key]) {
        formData[key] = decodeURIComponent(data[key])
        initialData[key] = decodeURIComponent(data[key])
      } else {
        formData[key] = data[key]
        initialData[key] = data[key]
      }
    })
    formData.revision_id = data.revision_id
  }

  const fetchByRouteId = async () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Thumbnail']

    if (id) {
      formData.id = id
      ctx.root.$vs.loading()
      $store
        .dispatch('seo/fetchOne', id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`seo/${formData.folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`seo/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  return {
    initialData,
    formData,
    prepareFormData,
    fetchByRouteId,
  }
}
