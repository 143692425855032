export default ({ root: { $vs } }) => {
  const success = ({
    color = 'success',
    title = 'Success',
    text = 'success message',
    position = 'top-right',
  }) => {
    $vs.notify({
      color,
      title,
      text,
      position,
    })
  }

  const error = ({
    color = 'danger',
    title = 'Error',
    text = 'error message',
    position = 'top-right',
  }) => {
    $vs.notify({
      color,
      title,
      text,
      position,
    })
  }

  const confirm = (context) => {
    const { title, message, color = 'warning', callback = () => {}, cancelCallback = () => {} } = context

    $vs.dialog({
      type: 'confirm',
      color,
      title: title || 'ยืนยัน',
      text: message || 'คุณแน่ใจหรือไม่',
      acceptText: 'ตกลง',
      cancelText: 'ยกเลิก',
      accept: callback,
      cancel: cancelCallback
    })
  }

  const warning = ({
    color = 'warning',
    title = 'Warning',
    text = 'warnning message',
    position = 'top-right',
  }) => {
    $vs.notify({
      color,
      title,
      text,
      position,
      time: 5000
    })
  }


  return {
    success,
    error,
    confirm,
    warning
  }
}
