import moment from 'moment'
moment.locale('th')
moment.locale('th', {
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ],
  weekdaysMin: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
})

export default () => {
  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
  }

  const dateRangeFormat = (stateDate, endDate) => {
    return `${moment(stateDate).format('DD MMM YYYY')} - ${moment(endDate).format(
      'DD MMM YYYY',
    )}`
  }

  const createdAtDatetimeFormat = (value) => {
    return moment(value).format('DD MMM YYYY HH:mm:ss')
  }

  const updatedAtDatetimeFormat = (value) => {
    return moment(value).format('DD MMM YYYY HH:mm:ss')
  }

  const disabledEndDate = (date, conditionDate) => {
    return moment(date).isBefore(conditionDate)
  }

  const disabledStartDate = (date, conditionDate, today = null) => {
    if (conditionDate) {
      if (today) {
        // const beforeToday = moment(today).subtract(1, 'days')
        return moment(date).isAfter(conditionDate, 'day') || moment(date).isBefore(today, 'day')
      }
      return moment(date).isAfter(conditionDate, 'day')
    } else if (today) {
      // const beforeToday = moment(today).subtract(1, 'days')
      return moment(date).isBefore(today, 'day')
    }
    return false
  }

  const formatMoment = (date) => {
    return moment(date).format()
  }

  return {
    formatDate,
    moment,
    dateRangeFormat,
    createdAtDatetimeFormat,
    updatedAtDatetimeFormat,
    disabledStartDate,
    disabledEndDate,
    formatMoment
  }
}
