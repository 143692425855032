<template>
  <!-- ITEMS PER PAGE -->
  <vs-dropdown 
    v-if="queriedItems > 0"
    vs-trigger-click 
    class="cursor-pointer mb-4 mr-4 items-per-page-handler">
    <div
      :class="!customClass ? 'p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium' : customClass"
    >
      <span class="mr-2">
        {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
        {{
          queriedItems - currentPage * itemsPerPage > 0
            ? currentPage * itemsPerPage
            : queriedItems
        }}
        of {{ queriedItems }}
      </span>
      <feather-icon 
        icon="ChevronDownIcon" 
        svg-classes="h-4 w-4" />
    </div>
    <vs-dropdown-menu>
      <vs-dropdown-item @click="$emit('click', 4)">
        <span>4</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="$emit('click', 10)">
        <span>10</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="$emit('click', 15)">
        <span>15</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="$emit('click', 20)">
        <span>20</span>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
export default {
  name: 'LhDropdownPerPage',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true  
    },
    queriedItems: {
      type: Number,
      required: true
    }
  }
}
</script>