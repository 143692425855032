<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewContent"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New Content</span>
          </div>

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th width="30%" sort-key="seo_name">ชื่อ</vs-th>
          <vs-th width="40%" sort-key="url">URL</vs-th>
          <vs-th width="15%" sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th width="15%" >Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)" width="10%">{{ tr.seo_name }}</td>
              <!-- <vs-td>{{ tr.url }}</vs-td> -->
              <td class="td vs-table--td url" @click.stop="editData(tr.id)" width="60%" v-if="tr.url.startsWith('http')">
                <span>{{ tr.url}} </span>
                <a 
                  @click.stop=""
                  :href="tr.url" 
                  target="_blank"><link-icon size="1.0x" />
                </a>
              </td>
              <td class="td vs-table--td url" @click.stop="editData(tr.id)" width="60%" v-else>
                <span>{{tr.url}} </span>
                <a 
                  @click.stop=""
                  :href="websiteUrl + tr.url" 
                  target="_blank"><link-icon size="1.0x" />
                </a>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)" width="15%">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td width="15%" class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.prevent="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
// import get from 'lodash/get'
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import useSEOUtil from '@/use/useSEOUtil'
import { LinkIcon } from 'vue-feather-icons'
import omit from 'lodash/omit'
import get from 'lodash/get'
import env from '@/env'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'SEOListView',
  components: {
    LinkIcon,
    LhDropdownPerPage,
    CustomTable
  },
  data() {
    return {
      websiteUrl: `${env.VUE_APP_WEBSITE_URL}`.replace(/\/$/, "")
    }
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'seo')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useSEOUtil(ctx)

    crudFunction.routePrefix.value = 'seo'

    const items = computed(() => {
      return get(crudFunction, ['result', 'value'], []).map((item) => {
        return {
          ...item,
          url: decodeURIComponent(item.url)
        }
      })
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'seo/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      const created_by = email
      const updated_by = email

      formData.url = formData.url.concat('_copy')
      const resultDuplicate = await ctx.root.$store.dispatch(
        'seo/addItem',
        {
          ...omit(formData, ['id', 'revision_id', 'updated_at']),          
          created_by,
          updated_by
        },
      )
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    const addNewContent = () => {
      ctx.root.$router.push({
        name: 'seo-create',
      })
    }

    return {
      ...crudFunction,
      items,
      addNewContent,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      searchKeys: ['seo_name', 'url']
    }
  },
}
</script>

<style scoped lang="scss">
  .url {
    span {
      word-break: break-all;
      max-width: 700px;
    }
  }
</style>
