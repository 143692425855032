import { ref, computed, onMounted, watch } from '@vue/composition-api'
import useNotify from '@/use/useNotify'
import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'
import omit from 'lodash/omit'
import moment from 'moment'

const defaultOptions = {
  fetchOnMount: true,
  onModifyItemSuccess: undefined,
}

/**
 * @callback onModifyItemSuccess
 * @param {string} id
 */

/**
 * @param {Object} _options
 * @param {bool} _options.fetchOnMount
 * @param {onModifyItemSuccess} _options.onModifyItemSuccess
 */
export default (ctx, storeName, _options) => {
  const selected = ref([])
  const itemsPerPage = ref(10)
  const addNewDataSidebar = ref(false)
  const sidebarData = ref({})
  const isMounted = ref(false)
  const routePrefix = ref('')
  const isPopupDuplicateActive = ref(false)
  const contentDuplicateId = ref('')
  const {
    refs,
    root: { $store, $router, $vs, $route },
  } = ctx
  const {
    success: notifySuccess,
    error: notifyError,
    confirm: notifyConfirm,
  } = useNotify(ctx)
  const options = {
    ...defaultOptions,
    ..._options,
  }

  watch(
    () => ctx.root.$store.state.locale.currentLang,
    async (newValue, oldValue) => {
      // await ctx.root.$vs.loading()
      if (refs.table) {
        refs.table.currentx = 1
      }
      itemsPerPage.value = 10
      
      if (newValue && oldValue) {
        if ($route.path.indexOf('/property/promotion/edit') === -1) {
          await ctx.root.$vs.loading()
          await $store.dispatch(`${storeName}/fetch`)
          await ctx.root.$vs.loading.close()
        }
      }
      // fetch new project promotion for show badge
      if (
        $route.path.indexOf('/property/promotion') === -1
        && $route.path.indexOf('/lh-contents/project-review') === -1
        && $route.path.indexOf('/property-asset/plan/master-gallery-homesell') === -1
      ) {
        await $store.dispatch('promotion/fetch')
        await $store.dispatch('project/fetchProjectName')  
      }
    
      if ($route.path.indexOf('/lh-contents/project-review') > -1
        || $route.path.indexOf('/property-asset/plan/master-gallery-homesell') > -1
      ) {
        await $store.dispatch('promotion/fetch')
      }

      // await ctx.root.$vs.loading.close()
    },
  )

  watch(
    () => itemsPerPage.value,
    () => {
      if (refs.table) {
        refs.table.currentx = 1
      }
    }
  )

  onMounted(async () => {
    if (options.fetchOnMount) {
      await ctx.root.$vs.loading()
      await $store.dispatch(`${storeName}/fetch`)
      await ctx.root.$vs.loading.close()
    }

    isMounted.value = true
  })

  const currentPage = computed(() => {
    if (isMounted.value && refs.table) {
      return refs.table.currentx
    }
    return 0
  })

  const result = computed(() => {
    return $store.state[storeName].items
  })

  const queriedItems = computed(() => {
    return refs.table ? refs.table.queriedResults.length : get(result, 'value', []).length
  })

  const addItem = ({ validate }, formData) => {
    const callback = () => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลที่กรอก',
          })
          return
        }

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        // add user created by
        if (!formData.created_by) {
          created_by = email
        }
        const updated_by = email

        $vs.loading()
        $store
          .dispatch(`${storeName}/${formData.id ? 'updateItem' : 'addItem'}`, {
            ...omit(formData, ['updated_at', 'created_at']),
            created_by,
            updated_by
          })
          .then(() => {
            if (formData.revision_id) {
              $store.dispatch(`${storeName}/delete`, formData.revision_id)
            }

            // if no routePrefix params, it means update form is on the same page so we have to refresh data after update successfully
            if (routePrefix.value) {
              $router.push({ name: `${routePrefix.value}` })
            } else {
              $store.dispatch(`${storeName}/fetch`)
            }

            if (options.onModifyItemSuccess) {
              options.onModifyItemSuccess(formData.id)
            }

            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    if (formData.id) {
      return notifyConfirm({ callback })
    }

    callback()
  }

  const createData = () => {
    $router.push({ name: `${routePrefix.value}-create` })
  }

  const updateData = (id) => {
    $router.push({ name: `${routePrefix.value}-edit`, params: { id } })
  }

  const converseMessageToHuman = (message) => {
    if (message.search('Foreign key violation. update or delete on table "house_function" violates foreign key constraint "house_model_function_house_function_id_fkey" on table "house_model_function"') >= 0) { 
      return `ไม่สามารถลบฟังก์ชันบ้านได้ เนื่องจากมีแบบบ้านใช้งานอยู่`
    } else if (message) {
      return message
    }

    return 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ'
  }

  const deleteData = (id) => {
    $vs.dialog({
      type: 'confirm',
      color: 'danger',
      title: 'Confirm',
      text: 'ต้องการลบข้อมูลหรือไม่',
      acceptText: 'ตกลง',
      cancelText: 'ยกเลิก',
      accept: () => {
        $store
          .dispatch(`${storeName}/delete`, id)
          .then(() => {
            $store.dispatch(`${storeName}/fetch`)

            notifyError({
              title: 'ลบข้อมูล',
              text: 'ลบข้อมูลเรียบร้อยแล้ว',
            })
          })
          .catch((error) => {
            console.log(error)
            notifyError({
              title: 'ลบข้อมูล',
              text: converseMessageToHuman(error.message),
            })
          })
      },
    })
  }

  const isShowItemPerPage = (numData, pageIndex) => {
    return numData >= pageIndex
  }

  const duplicateContent = (contentId) => {
    contentDuplicateId.value = contentId
    isPopupDuplicateActive.value = true
  }

  const handlerDuplicateContent = async (
    formData,
    prepareFormData,
    langToDuplicationContent,
  ) => {
    ctx.root.$vs.loading()
    await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
    const result = await ctx.root.$store.dispatch(
      `${storeName}/fetchOne`,
      contentDuplicateId.value,
    )
    await prepareFormData(result)
    if (!formData.folder_name) {
      const randomUuid = uuidv4()
      formData.folder_name = randomUuid
    }

    const activeUser = ctx.root.$store.state.AppActiveUser
    const email = get(activeUser, 'email', '')

    await createFolder(`${storeName}/${formData.folder_name}`, ['Cover', 'File'])
    const resultDuplicate = await ctx.root.$store.dispatch(`${storeName}/addItem`, {
      ...omit(formData, ['created_at', 'updated_at']),
      created_by: email,
      updated_by: email,
      id: null,
    })
    isPopupDuplicateActive.value = false
    await ctx.root.$vs.loading.close()
    await ctx.root.$router.push({
      name: `${routePrefix.value}-edit`,
      params: {
        id: resultDuplicate.revision_id,
      },
    })
  }

  const editData = (id) => {
    // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
    ctx.root.$router.push({
      name: `${routePrefix.value}-edit`,
      params: { id },
    })
  }

  const translateLang = {
    'th': 'ภาษาไทย',
    'en': 'ภาษาอังกฤษ',
    'cn': 'ภาษาจีน'
  }

  const formatDate = (date) => {
    moment.locale('th')
    return date ? moment(date).format('LLL') : ''
  }

  return {
    selected,
    itemsPerPage,
    addNewDataSidebar,
    sidebarData,
    currentPage,
    result,
    queriedItems,
    routePrefix,
    isPopupDuplicateActive,
    contentDuplicateId,
    duplicateContent,
    handlerDuplicateContent,
    addItem,
    createData,
    updateData,
    deleteData,
    isShowItemPerPage,
    translateLang,
    formatDate,
    editData
  }
}
